// import React from 'react';
// import Introduction from 'docs/Introduction';

// const IntroductionPage = () => {
//   return <Introduction />;
// };

// export default IntroductionPage;
import React from 'react';
import ServerError from 'views/Error';

const ErrorPage = () => {
  return <ServerError />;
};

export default ErrorPage;